import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideFooter from '../components/SideFooter';

import heroImage from '../assets/images/pexels-tom-swinnen-1309766.jpg';
import pic2 from '../assets/images/kanjiverse-thumbnail.png';
import ContactForm from '../components/ContactForm';

const IndexPage = () => (
  <Layout>
    <div id="wrapper">
      <section className="intro">
        <Header />
        <div className="content">
          <span className="image fill" data-position="center">
            <img src={heroImage} alt="Photo by Tom Swinnen from Pexels" />
          </span>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>Projects</h2>
        </header>
        <div className="content">
          <p style={{ color: '#000000' }}>
            <b style={{ color: '#000000' }}>Kanjiverse</b> aims to become an
            ecosystem for learners of Japanese, Chinese and Korean, that focuses
            on reading skills. Our first app is a new kind of{' '}
            <i>Japanese Learner's Dictionary</i> that will launch soon on iOS,
            Android and web.
          </p>
          <span className="image main">
            <a href="https://kanjiverse.com">
              <img src={pic2} alt="" />
            </a>
          </span>
        </div>
      </section>

      <section>
        <header>
          <h2>Team</h2>
        </header>
        <div className="content">
          <div
            class="LI-profile-badge"
            data-version="v1"
            data-size="medium"
            data-locale="en_US"
            data-type="horizontal"
            data-theme="light"
            data-vanity="cyril-clement"
          >
            <a
              class="LI-simple-link"
              href="https://jp.linkedin.com/in/cyril-clement?trk=profile-badge"
            >
              Cyril Clement
            </a>
          </div>
        </div>
      </section>

      <section>
        <ContactForm />
        <SideFooter />
      </section>

      <Footer />
    </div>
  </Layout>
);

export default IndexPage;
