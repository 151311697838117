module.exports = {
  siteTitle: 'App Brewing Company Ltd.', // <title>
  manifestName: 'appbrew',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ffffff',
  manifestThemeColor: '#ffffff',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/landing/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'App Brewing Company Ltd.',
  subHeading: 'Indie Dev Studio',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/cyril-clement/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/kanjiverse',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/kanjiverse',
    },
  ],
  emailId: 'contact@appbrew.co',
  phone: '',
  address: 'App Brewing Company Ltd, Intershore Chambers, Road Town, Tortola VG1110, British Virgin Islands',
};
